export const DOCUMENT_FORM = 'DocumentForm'

export const FIELD_TEXT = 'text'
export const FIELD_DATE = 'date'
export const FIELD_PASSWORD = 'password'
export const FIELD_SELECT = 'select'
export const FIELD_RADIO = 'radio'
export const FIELD_TEXTAREA = 'textarea'
export const FIELD_SCALE = 'scale'
export const FIELD_HIDDEN = 'hidden'
export const FIELD_CHECKBOX = 'checkbox'
export const FIELD_FILES = 'files'
export const FIELD_DATE_PICKER = 'date_picker'

export const FIELDS = [FIELD_SELECT, FIELD_RADIO, FIELD_TEXTAREA, FIELD_SCALE]

export const QUESTION_TYPE_TEXT = 'text'
export const QUESTION_SINGLE_CHOICE = 'single_choice'
export const QUESTION_MULTIPLE_CHOICE = 'multiple_choice'
export const QUESTION_SCALE_UP_TO_5 = 'up_to_5'
export const QUESTION_SCALE_UP_TO_10 = 'up_to_10'

export const GENDER_M = 'Mr'
export const GENDER_MME = 'Mrs'
export const BOARDING_FORM_SUGGESTION_MAX_LENGTH = 250
export const BOARDING_FORM_SUCCESTION_KEY = 'suggestion'

export const MORNING = 'morning'
export const AFTERNOON = 'afternoon'

export const CONTACT_TYPE = [
  {
    name: 'contactForm_type_interested_partner',
    code: 'interested_partner',
  },
  {
    name: 'contactForm_type_client',
    code: 'client',
  },
  {
    name: 'contactForm_type_registered_pro',
    code: 'registered_pro',
  },
  {
    name: 'contactForm_type_interested_pro',
    code: 'interested_pro',
  },
  {
    name: 'contactForm_type_applicant',
    code: 'applicant',
  },
  {
    name: 'contactForm_type_press',
    code: 'press',
  },
  {
    name: 'contactForm_type_other',
    code: 'other',
  },
]
