import {
  generateRequestActions,
  createRequestTypes,
  generateSimpleAction,
} from 'utils/actionHelpers'
import { CONTEXT_CLOSE_ALL } from '../context/actions'

export const LEAD_SALE_FIRMS = createRequestTypes('LEAD_SALE_FIRMS')
export const FIRM_DETAILS_BY_HASHID = createRequestTypes(
  'FIRM_DETAILS_BY_HASHID',
)
export const FIRM_DETAILS = createRequestTypes('FIRM_DETAILS')

export const FIRM_UPDATE = createRequestTypes('FIRM_UPDATE')
export const FIRM_TRADES_DELETE = createRequestTypes('FIRM_TRADES_DELETE')
export const FIRM_REVIEWS = createRequestTypes('FIRM_REVIEWS')
export const HASHID_DECODE = createRequestTypes('HASHID_DECODE')
export const FIRM_CURRENT = createRequestTypes('FIRM_CURRENT')
export const FIRM_ACTIVE_DIRECTORY_OPTION = createRequestTypes(
  'FIRM_ACTIVE_DIRECTORY_OPTION',
)
export const FIRM_PRO_DIRECTORY_CONF = createRequestTypes(
  'FIRM_PRO_DIRECTORY_CONF',
)
export const FIRM_PLACE_AUTO_COMPLETE_LOCATION =
  'FIRM_PLACE_AUTO_COMPLETE_LOCATION'
export const FIRM_SET_IS_LOGIN_REQUEST = 'FIRM_SET_IS_LOGIN_REQUEST'
export const FIRM_PLACE_CLEAR_AUTO_COMPLETE_LOCATION =
  'FIRM_PLACE_CLEAR_AUTO_COMPLETE_LOCATION'
export const FIRM_SELECT_DEFAULT_COVER = createRequestTypes(
  'FIRM_SELECT_DEFAULT_COVER',
)
export const FIRM_VALIDATE_EMAIL = createRequestTypes('FIRM_VALIDATE_EMAIL')
export const FIRM_SEND_VALIDATE_EMAIL = createRequestTypes(
  'FIRM_SEND_VALIDATE_EMAIL',
)
export const FIRM_SET_VALIDATION_EMAIL_TOKEN_STATUS =
  'FIRM_SET_VALIDATION_EMAIL_TOKEN_STATUS'
export const FIRM_SET_CANDIDATE_PACKAGE_VALIDATION_ERROR =
  'FIRM_SET_CANDIDATE_PACKAGE_VALIDATION_ERROR'
export const FIRM_UPDATE_LEGAL_INFO = createRequestTypes(
  'FIRM_UPDATE_LEGAL_INFO',
)
export const FIRM_GENERATE_CONTRACT = createRequestTypes(
  'FIRM_GENERATE_CONTRACT',
)
export const FIRM_GET_CONTRACT = createRequestTypes('FIRM_GET_CONTRACT')
export const FIRM_GET_FILES = 'FIRM_GET_FILES'
export const FIRM_GET_SINGLE_FILE = createRequestTypes('FIRM_GET_SINGLE_FILE')
export const REMOVE_REGISTER_FILE = createRequestTypes('REMOVE_REGISTER_FILE')

export const leadSaleFirms = generateRequestActions(LEAD_SALE_FIRMS)
export const firmDetailsByHashId = generateRequestActions(
  FIRM_DETAILS_BY_HASHID,
)
export const firmDetails = generateRequestActions(FIRM_DETAILS)
export const firmUpdate = generateRequestActions(FIRM_UPDATE)
export const firmTradesDelete = generateRequestActions(FIRM_TRADES_DELETE)
export const firmReviews = generateRequestActions(FIRM_REVIEWS)
export const hashidDecode = generateRequestActions(HASHID_DECODE)
export const firmCurrent = generateRequestActions(FIRM_CURRENT)
export const firmActiveDirectoryOption = generateRequestActions(
  FIRM_ACTIVE_DIRECTORY_OPTION,
)
export const firmProDirectoryConf = generateRequestActions(
  FIRM_PRO_DIRECTORY_CONF,
)
export const setFirmPlaceAutoCompleteLocation = generateSimpleAction(
  FIRM_PLACE_AUTO_COMPLETE_LOCATION,
)
export const setIsLoginRequest = generateSimpleAction(FIRM_SET_IS_LOGIN_REQUEST)
export const clearFirmPlaceAutoCompleteLocation = generateSimpleAction(
  FIRM_PLACE_CLEAR_AUTO_COMPLETE_LOCATION,
)
export const firmSelectDefaultCover = generateRequestActions(
  FIRM_SELECT_DEFAULT_COVER,
)

export const firmCloseAll = generateSimpleAction(CONTEXT_CLOSE_ALL)
export const firmGetContract = generateRequestActions(FIRM_GET_CONTRACT)
export const firmGetFiles = generateSimpleAction(FIRM_GET_FILES)
export const firmGetSingleFile = generateRequestActions(FIRM_GET_SINGLE_FILE)
export const firmValidateEmail = generateRequestActions(FIRM_VALIDATE_EMAIL)
export const firmSendValidateEmail = generateRequestActions(
  FIRM_SEND_VALIDATE_EMAIL,
)
export const firmUpdateLegalInfo = generateRequestActions(
  FIRM_UPDATE_LEGAL_INFO,
)
export const setValidationEmailTokenStatus = generateSimpleAction(
  FIRM_SET_VALIDATION_EMAIL_TOKEN_STATUS,
)
export const generateFirmContract = generateRequestActions(
  FIRM_GENERATE_CONTRACT,
)
export const setCandidatePackageValidationError = generateSimpleAction(
  FIRM_SET_CANDIDATE_PACKAGE_VALIDATION_ERROR,
)
export const removeRegisterFile = generateRequestActions(REMOVE_REGISTER_FILE)
export const FIRM_SET_FIRM_FILES = 'FIRM_SET_FIRM_FILES'
export const firmSetFirmFiles = generateSimpleAction(FIRM_SET_FIRM_FILES)
export const EDIT_FIRM_DETAILS_PRO_FORM = 'EDIT_FIRM_DETAILS_PRO_FORM'
export const UPDATE_FIRM_DETAILS_PRO = 'UPDATE_FIRM_DETAILS_PRO'
export const updateFirmDetailsPro = generateSimpleAction(
  UPDATE_FIRM_DETAILS_PRO,
)
export const UPDATE_FIRM_DETAILS = createRequestTypes('UPDATE_FIRM_DETAILS')
export const updateFirmDetails = generateRequestActions(UPDATE_FIRM_DETAILS)
export const UPDATE_FIRM_USER_DETAILS = createRequestTypes(
  'UPDATE_FIRM_USER_DETAILS',
)
export const ADD_FIRM_USER_DETAILS = createRequestTypes('ADD_FIRM_USER_DETAILS')
export const updateFirmUserDetails = generateRequestActions(
  UPDATE_FIRM_USER_DETAILS,
)
export const addFirmUserDetails = generateRequestActions(ADD_FIRM_USER_DETAILS)
export const UPDATE_PROFILES_FIRM_DETAILS_PRO =
  'UPDATE_PROFILES_FIRM_DETAILS_PRO'
export const updateProfilesFirmDetailsPro = generateSimpleAction(
  UPDATE_PROFILES_FIRM_DETAILS_PRO,
)
export const UPDATE_NEWSLETTER_OPTINS = 'UPDATE_NEWSLETTER_OPTINS'
export const updateNewsletterOptins = generateSimpleAction(
  UPDATE_NEWSLETTER_OPTINS,
)
export const SAVE_FIRM_COMMENT = createRequestTypes('SAVE_FIRM_COMMENT')
export const saveFirmComment = generateRequestActions(SAVE_FIRM_COMMENT)

export const SET_SUBJECT_TO_VAT = createRequestTypes('SET_SUBJECT_TO_VAT')
export const setSubjectToVat = generateRequestActions(SET_SUBJECT_TO_VAT)

export const SET_UNAVAILABILITY_PERIOD = createRequestTypes(
  'SET_UNAVAILABILITY_PERIOD',
)
export const setUnavailabilityPeriod = generateRequestActions(
  SET_UNAVAILABILITY_PERIOD,
)

export const DELETE_UNAVAILABILITY_PERIOD = createRequestTypes(
  'DELETE_UNAVAILABILITY_PERIOD',
)
export const deleteUnavailabilityPeriod = generateRequestActions(
  DELETE_UNAVAILABILITY_PERIOD,
)

export const SET_GEOGRAPHIC_ZONE = createRequestTypes('SET_GEOGRAPHIC_ZONE')
export const setGeographicZone = generateRequestActions(SET_GEOGRAPHIC_ZONE)
